import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { APIConstant } from '../constants/APIConstants';
import { lastValueFrom } from 'rxjs';
import { ObjectLiteral } from '../models/ObjectLiteral';
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  constructor(private appService: AppService) {}
  async getChartData(): Promise<any> {
    var url =
      'http://my-json-server.typicode.com/apexcharts/apexcharts.js/yearly2';

    const { data: chartData } = await axios({
      method: 'GET',
      url: url,
    });
    return chartData;
  }

  async getMockBill(params: ObjectLiteral): Promise<ObjectLiteral> {
    return lastValueFrom(
      this.appService.get(`${APIConstant.bills.getMockBill}`, params)
    );
  }

  async sendMockBillEmail(data: ObjectLiteral): Promise<ObjectLiteral> {
    return lastValueFrom(
      this.appService.post(`${APIConstant.bills.sendEmail}`, data)
    );
  }
}
